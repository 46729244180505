import './App.css';
import { useEffect, useState } from 'react';
import { getMemeCount } from './server';
import CountUp from 'react-countup';
import { Helmet } from 'react-helmet';

function App() {
  const [memeCount, setMemeCount] = useState(0);
  const [memes, setMemes] = useState([]);

  async function getCount() {
    const count = await getMemeCount();
    setMemes(count);
    setMemeCount(count.length);
  }

  useEffect(() => {
    getCount();
  }, []);

  // Get a random meme from the array
  const randomMeme = memes.length > 0 ? memes[Math.floor(Math.random() * memes.length)] : null;

  return (
    <div className="min-h-screen bg-[#b7d9ff] grid items-center justify-center">
      <Helmet>
        <meta property="og:title" content={`${memeCount} NUB MEMES`} />
        <meta property="og:description" content="Current meme count on nub.lol" />
        {randomMeme && <meta property="og:image" content={randomMeme.source} />}
        <meta property="og:url" content="https://nub.lol" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="p-8 rounded-lg">
        <p className="text-5xl text-white text-center font-bold" style={{ fontFamily: 'Calibri, sans-serif' }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <CountUp end={memeCount} duration={2.75} />
            <div style={{ fontSize: "1rem", lineHeight: "1" }}>
              <div style={{ textAlign: "left", justifyItems: "center", alignContent: "center", alignItems: "center", }} >
                <span style={{ letterSpacing: "7px" }}>NUB</span>
                <div>
                  MEMES
                </div>
              </div>
            </div>
          </div>
          <div style={{ lineHeight: "20px" }}>
            <p style={{ lineHeight: "13px" }} className="text-[13px]">on</p>
            <a style={{ lineHeight: "13px" }} className="text-[2rem]" href="https://nub.lol">nub.lol</a>
          </div>
        </p>
      </div>

      <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center">
        <div className={`w-4 h-4 rounded-full mr-2 ${memeCount > 0 ? 'bg-green-500' : 'bg-red-500'} animate-pulse`}></div>
        <span className="text-lg font-bold" style={{ fontFamily: 'Calibri, sans-serif' }}>
          {memeCount > 0 ? 'System Operational' : 'No Memes Available'}
        </span>
      </div>
    </div>
  );
}

export default App;
